import React, { useState } from 'react';
import logo from '../../images/logo.svg';
import phone from '../../images/icon-call.svg';
import email from '../../images/icon-email.svg';
import downRu from '../../images/to-down.svg';
import downEn from '../../images/to-down-en.svg';
import close from '../../images/icon-cross.svg';
import menu from '../../images/icon-hamburger.svg';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Search from '../utils/search';
import cn from 'classnames';
import Helmet from 'react-helmet';
import { t } from 'i18next';
import LanguageMenu from '../utils/languageMenu';
import { useTranslation } from 'react-i18next';

const Lead = ({
  subtitle,
  title,
  leadVideo,
  leadPhoneText,
  leadPhoneNumber,
  leadLogo,
  leadEmail,
  videoClass,
}) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const togglePhone = () => {
    setOpenPhone(!openPhone);
  };

  const toggleMail = () => {
    setOpenMail(!openMail);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const {
    t,
    i18n
  } = useTranslation();

  return (
    <section
      id="top"
      className={cn('lead', {
        'lead--black': openSearch,
        'lead--menu': openMenu,
      })}
    >
      <div
        className={
          videoClass
            ? `lead__video-wrapper ` + videoClass
            : `lead__video-wrapper`
        }
      >
        <video autoPlay muted loop playsInline className="lead__video">
          <source
            src={leadVideo}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
      </div>
      <header className="nav-header">
        <div className="nav-header__wrapper wrapper">
          <Link className="nav-header__logo" to="/">
            <img src={logo} width="108" height="80" alt="AM.PM"/>
          </Link>
          <div className="nav-header__menu">
            <button
              className="nav-header__hamburger-button"
              type="button"
              aria-label="toggle mobile menu"
              onClick={toggleMenu}
            >
              <img src={openMenu ? close : menu} alt=""/>
            </button>
            <div className="nav-header__tools tools">
              <LanguageMenu/>
              <a
                href="tel:88002002929"
                className={cn('tools__phone', {
                  'tools__phone--active': openPhone,
                })}
              >
                8 800 200-29-29
              </a>
              <button
                className="tools__item tools__item--phone"
                onClick={togglePhone}
              >
                <img
                  src={openPhone ? close : phone}
                  width="20"
                  height="20"
                  alt="Заказать обратный звонок"
                />
              </button>
              <a
                href="mailto:www.test.ru"
                className={cn('tools__mail', {
                  'tools__mail--active': openMail,
                })}
              >
                ic@aquaart.ru
              </a>
              <button
                className="tools__item tools__item--mail"
                onClick={toggleMail}
              >
                <img
                  src={openMail ? close : email}
                  width="20"
                  height="20"
                  alt="Email"
                />
              </button>
            </div>
            <nav className="nav-header__links nav-links">
              <Link className="nav-header__link nav-link" to="/about-company/">
                {t('lead.navigation.aboutCompany')}
              </Link>
              <Link className="nav-header__link nav-link" to="/brands/">
                {t('lead.navigation.ourBrands')}
              </Link>
              {
                i18n.language === 'ru' &&
                <>
                  <Link className="nav-header__link nav-link" to="/career/">
                    {t('lead.navigation.career')}
                  </Link>
                  <Link className="nav-header__link nav-link" to="/reviews/">
                    {t('lead.navigation.review')}
                  </Link>
                </>
              }
              <Link className="nav-header__link nav-link" to="/contacts/">
                {t('lead.navigation.contact')}
              </Link>
            </nav>
          </div>
        </div>
      </header>
      <div className="lead__wrapper wrapper">
        <div className="lead__main-content">
          <p className="lead__subtitle">
            {subtitle ? subtitle : ''}
            <img
              src={leadLogo ? leadLogo : ''}
              alt=""
              className={leadLogo ? '' : 'visually-hidden'}
            />
          </p>
          <h1 className="lead__title big-text" dangerouslySetInnerHTML={{ __html: title }}/>
          <footer className="lead__footer">
            <div className="lead__phone">
              <p>{leadPhoneText ? leadPhoneText : ''}</p>
              <a href="tel:88002002929">
                {leadPhoneNumber ? leadPhoneNumber : ''}
              </a>
              <a href="mailto:info@aquaart.ru" className="lead__email">
                {leadEmail ? leadEmail : ''}
              </a>
            </div>
            <AnchorLink
              className="lead__anchor-wrapper arrow-down "
              href="#second"
            >
              <img
                src={i18n.language === 'ru' ? downRu : downEn}
                width="120"
                height="120"
                alt="Вниз"
                className="lead__move-down"
              />
            </AnchorLink>
          </footer>
        </div>
        <Search/>
      </div>
    </section>
  );
};

export default Lead;
