import $ from "jquery";

/*$(document).ready(function () {
  const html = document.documentElement;

  window.addEventListener("scroll", () => {
    const scrollTop = html.scrollTop;
    const windowHeight = $(window).height();
    const maxScrollTop = windowHeight * 1.27;

    // Если закончилась первая анимация

    // Фиксирование первого слайда

    if (scrollTop > maxScrollTop && scrollTop < maxScrollTop * 2) {
      $(".slider__item--start").css("display", "flex");
      let currentTopSecond =
        (((scrollTop - maxScrollTop) / maxScrollTop) * 100 - 100) * -1.09;
      $(".slider__item--start").css("top", currentTopSecond + "vh");
    } else {
      if (scrollTop > maxScrollTop * 1.5) {
        $(".slider__item--start").css("top", "0");
      }

      if (scrollTop > maxScrollTop * 2.25) {
        $(".slider__item--start .slider__text-wrapper").fadeIn();
      }
    }

    if (scrollTop > maxScrollTop * 2 && scrollTop < maxScrollTop * 3) {
      $(".slider__item--culture").css("display", "flex");
      $(".slider__item--culture").css("top", "100%");
    } else {
      if (scrollTop > maxScrollTop * 2) {
        $(".slider__item--culture").css("top", "0");
      }

      if (scrollTop > maxScrollTop * 3.25) {
        $(".slider__item--culture .slider__text-wrapper").fadeIn();
      }
    }

    if (scrollTop > maxScrollTop * 3 && scrollTop < maxScrollTop * 4) {
      $(".slider__item--brand").css("display", "flex");
      $(".slider__item--brand").css("top", "100%");
    } else {
      if (scrollTop > maxScrollTop * 3) {
        $(".slider__item--brand").css("top", "0");
      }

      if (scrollTop > maxScrollTop * 4.25) {
        $(".slider__item--brand .slider__text-wrapper").fadeIn();
      }
    }

    if (scrollTop > maxScrollTop * 5 && scrollTop < maxScrollTop * 6) {
      $(".values").fadeIn();
    } else {
      if (scrollTop < maxScrollTop * 6) {
        $(".values").fadeOut();
      }
    }

    if (scrollTop > maxScrollTop * 5.5 && scrollTop < maxScrollTop * 6.5) {
      $(".slider__item--open").css("display", "flex");
      $(".slider__item--open").css("top", "0");
    } else {
      if (scrollTop > maxScrollTop * 5.5) {
        $(".slider__item--open .slider__text-wrapper").fadeIn();
      }
      if (scrollTop < maxScrollTop * 6.5) {
        $(".slider__item--open").css("display", "none");
      }
    }

    if (scrollTop > maxScrollTop * 6 && scrollTop < maxScrollTop * 7.5) {
      $(".slider__item--ip").css("display", "flex");
      $(".slider__item--ip").css("top", "100%");
    } else {
      if (scrollTop > maxScrollTop * 7) {
        $(".slider__item--ip").css("top", "0");
      }
      if (scrollTop > maxScrollTop * 8) {
        $(".slider__item--ip .slider__text-wrapper").fadeIn();
      }
      if (scrollTop < maxScrollTop * 7) {
        $(".slider__item--ip").css("display", "none");
      }
    }

    if (scrollTop > maxScrollTop * 7.5 && scrollTop < maxScrollTop * 8.5) {
      $(".slider__item--creativity").css("display", "flex");
      $(".slider__item--creativity").css("top", "100%");
    } else {
      if (scrollTop > maxScrollTop * 7.5) {
        $(".slider__item--creativity").css("top", "0");
      }
      if (scrollTop > maxScrollTop * 8.75) {
        $(".slider__item--creativity .slider__text-wrapper").fadeIn();
      }
      if (scrollTop < maxScrollTop * 8.5) {
        $(".slider__item--creativity").css("display", "none");
      }
    }

    if (scrollTop > maxScrollTop * 8.5 && scrollTop < maxScrollTop * 9.5) {
      $(".about-footer").css("display", "block");
      $(".about-footer").css("top", "100%");
    } else {
      if (scrollTop > maxScrollTop * 8.5) {
        $(".about-footer").css("top", "0");
      }
      if (scrollTop > maxScrollTop * 9.75) {
        $(".slider__item--attention .slider__text-wrapper").fadeIn();
      }
      if (scrollTop > maxScrollTop * 10) {
        $(".about-footer").css("overflow-y", "scroll");
      }
      if (scrollTop < maxScrollTop * 8.5) {
        $(".about-footer").css("display", "none");
      }
    }
  });
});*/

$(".nav-header__hamburger-button").on("click", function () {
  $("body").toggleClass("hold");
});

$(".nav-header__link").on("click", function () {
  $("body").removeClass("hold");
});

$(".modal__close").on("click", function () {
  $(".modal-wrapper").removeClass("modal-wrapper--active");
});
